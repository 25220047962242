import React, {useState} from 'react';

const Signup = () => {
  const [email, setEmail] = React.useState("");
  const [csrf, setCsrf] = React.useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [errors, setErrors] = React.useState(null);
  // Initialize the CSRF token
  React.useEffect(() => {
    setCsrf(document.querySelector('meta[name="csrf-token"]').content);
  }, []);

  const handleEmailChange = (e) => { 
    setEmail(e.target.value) 
  } 
  const handleFormSubmit = (e) => { 
    e.preventDefault();
    if (submitting) {
      return; // Prevent multiple form submissions
    }
    setSubmitting(true);
    fetch('/form_submissions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf,
      },
      body: JSON.stringify({ form_submission: { email } }),
    }).then((res) => res.json())
    .then((data) => {
      if(data.form_submission.success) {
        setErrors(null);
        setSubmitted(true);
      } else {
        setErrors(data.form_submission.errors);
      }
      setSubmitting(false);
    }).catch((error) => {
      setErrors(["An error occurred. Please try again."]);
      setSubmitting(false);
    });
  };

  return (
    <div>
      <div className="flex flex-col mx-auto lg:flex-row md:mx-0 md:pl-8">
        {errors && <div className="text-nifty-red text-footer-link font-roboto-mono mb-[20px]">{errors}</div>}
        {submitted && <div className="text-smallest font-roboto-condensed-black text-nifty-black mb-[20px]">Success! Check your inbox and claim your first NFT!</div>}
      </div>
      <div className="flex flex-col mx-auto lg:flex-row md:mx-0 md:pl-8">
        {/* TODO: Fix this CSS to break the new line */}
        <form className="lg:flex" onSubmit={handleFormSubmit}>
          <input className="px-8 mr-3 input-nifty-sign-up font-roboto-mono text-nifty-black focus:outline-none" placeholder="Your email address" disabled={submitted} type="email" name="form_submission[email]" id="form_submission_email" value={email} onChange={handleEmailChange} />
          <input type="submit" name="commit" value="Sign up" id="form-submit-btn" className="mt-4 mr-2 lg:mt-0 btn-nifty btn-nifty-sign-up font-roboto-mono-medium focus:outline-none" disabled={submitted || submitting} data-disable-with="Sign up" />
        </form>
      </div>
    </div>
  );
};

export default Signup;